import React from "react";
import NavLinks from "../components/navLinks"
import { Link, graphql, useStaticQuery } from "gatsby";
import SEO from "./SEO";

export default function Layout({ children, title  }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }`
  );
  return (
    <>
    <SEO />
    <div style={{ padding: `0 1rem` }}>
      <header style={{ marginBottom: `1.5rem` }}>
        <Link to="/" style={{ textShadow: `none`, backgroundImage: `none`, textDecoration: `none` }}>
          <h3 style={{ display: `inline` }}>{data.site.siteMetadata.title}</h3>
        </Link>
        <NavLinks />
      </header>
      {!title ? <></> :
        <h1>{title}</h1>
      }
      {children}
    </div>
    </>
  )
}

