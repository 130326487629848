import React from "react";
import { Helmet } from "react-helmet";

export default function SEO () {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Sexy Meme of The Day</title>
            <link rel="canonical" href="https://SexyMemeOfTheDay.com" />
        </Helmet>
    )
}